

// var sesameopening = false;
// function sesameopen($el, action){

// 	//if (sesameopening) return false;

// 	if( (action=='open' && $el.hasClass('opened')) ||  (action=='close' && !$el.hasClass('opened')) ) return false;
// 	else if( action=='open'  && !$el.hasClass('opened') ) open($el);
// 	else if( action=='close' && $el.hasClass('opened') ) close($el);
// 	else if( action=='toggle' && !$el.hasClass('opened') ) open($el);
// 	else if( action=='toggle' && $el.hasClass('opened') ) close($el);
// 	else{
// 		console.log('sesamopen() returned false');
// 		return false;
// 	}


// 	function open($el){
// 		sesameopening = true;

// 		$el.addClass('opened').css('height', 'auto');
		
// 		var myheight = $el.height();
		
// 		$el.css('height', '0px');

// 		setTimeout(function(){
// 			$el.css('height', myheight+'px');
// 		}, 10);

// 		$el.on('transitionend', function(event) { console.log('transitionend');
// 			event.preventDefault();
// 			$el.css('height', 'auto');
// 			$el.off();
// 			sesameopening = false;
// 		});
// 	}

// 	function close(){
// 		sesameopening = true;

// 		$el.css('height', $el.height()+'px' );

// 		setTimeout(function(){
// 			$el.removeClass('opened');
// 		}, 10);

// 		$el.on('transitionend', function(event) { console.log('transitionend');
// 			event.preventDefault();
// 			sesameopening = false;
// 		});
// 	}
// 	//DONT FORGET TO ADD CSS: :not(.opened){ height:0 !important; }
// }

$.fn.akmaxheight = function(initmaxheight){ console.log( '.akmaxheight() called' );
	var $this = this;

	initmaxheight = typeof initmaxheight !== 'undefined' ? initmaxheight : '0px';

	$this.css('max-height', 'none');
	var myheight = $this.height();
	$this.css('max-height', initmaxheight);

	$this.attr('data-akmaxheight', myheight);

	setTimeout(function(){
		$this.css('max-height', myheight+'px');
	}, 10);

}

$.fn.akmaxheightbyclass = function(classname){
	var $this = this;

	console.log( '.akmaxheight() called. Init-height: '+$this.height() );

	$this.addClass(classname);
	var myheight = $this.height();
	$this.removeClass(classname);

	$this.attr('data-akmaxheight', myheight);

	setTimeout(function(){
		$this.css('max-height', myheight+'px');
	}, 1);
}


$.fn.akcycleimg = function($el) {

	var $akcycleimg = this.find($el);

	if ( ! $akcycleimg.next().length ) return false;
	
	$akcycleimg.addClass('akcycleimg akcycleimg-init');

	console.log( '.akcycleimg() called on'+$akcycleimg.length +' imgs' );

	$akcycleimg.hide().first().show();
	
	$akcycleimg.off();
	$akcycleimg.on('click', function(event) {

		$akcycleimg.removeClass('akcycleimg-init');

		event.preventDefault()
		$(this).next().length ? $(this).hide().next().show() : $(this).hide().siblings().first().show();
		
	});

	// SCSS:

	// &.akcycle{
	// 	position: relative;
		
	// 	&.akcycle-init:before{
	// 		content:'\2192';
	// 		position: absolute;
	// 		left: 50%;
	// 		top: 50%;
	// 		height: 2rem;
	// 		width: 2rem;
	// 		line-height: 2.1rem;
	// 		border-radius: 2rem;
	// 		@include transform(translate(-50%,-50%));
	// 		text-align: center;
	// 		background: #fff;

	// 	}
	// 	cursor:e-resize;
	// }

};

//BILDSCHIRM BY AK
var refreshcount = 0;
var ratio, bb, bh;

$.fn.bildschirm = function(){

	bb = $(window).width();
	bh = $(window).height(); 
	
	ratio = (bb/bh > 1) ? 'landscape' : 'portrait'; 
	//shape = (bb/bh >= 0.75 && bb/bh <= 1.5) ? 'square' : 'panorama';

	is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	is_HD = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
	is_Retina = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
	is_iPhone = ((bb <= 320 && bh <= 568) || (bb <= 568 && bh <= 320)) && is_mobile;
	is_iPhone_35 = ((bb <= 320 && bh <= 450) || (bb <= 450 && bh <= 320)) && is_mobile;
	is_iPad = ((bb <= 768 && bh <= 1024) || (bb <= 1024 && bh <= 768)) && !is_iPhone && is_mobile;

	if ( ( bb >= 1350 && bh >= 900 && ratio=='landscape' ) || ( bb >= 675 && bh >= 900 && ratio=='portrait' ) ) size = 'XL';
	else if ( ( bb >= 960 && bh >= 640 && ratio=='landscape' ) || ( bb >= 480 && bh >= 640 && ratio=='portrait' ) ) size = 'L';
	else if ( ( bb >= 720 && bh >= 480 && ratio=='landscape' ) || ( bb >= 360 && bh >= 480 && ratio=='portrait' ) ) size = 'M';
	else size = 'S';

	//OUTPUT:

	this.removeClass('retina mobile desktop S M L XL landscape portrait square panorama iPad iPhone');
	
	if (is_Retina) $els.addClass('retina');
	if (is_iPhone) $els.addClass('iPhone');
	if (is_iPad) $els.addClass('iPad');
	
	if (is_mobile) $els.addClass('mobile');
	else $els.addClass('desktop');
	
	//$els.addClass(ratio+' '+shape);
	$els.addClass(ratio);
	$els.addClass(size);
};
//BILDSCHIRM END

function hello(){

}

var hello2 = function(){

} 






