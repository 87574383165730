var main = function(){
	$('article.field-name-field-images').each(function(){
		$(this).akcycleimg( $('div.items'));
	});
}//main();

var fixmenu = function(){ console.log('fixmenu initiated');

    $('header nav ul li').each(function(){

      var href = $(this).find('a').attr('href');
      console.log(href);
      if (href.toLowerCase().indexOf("node") >= 0) $(this).remove();

    });

}; fixmenu();

var nav = function(){

	$(document).on('mouseleave', 'header.nomobi nav', function(event) {
		event.preventDefault();
		$('.hover').removeClass('hover');
	});

	$(document).on('mouseenter', 'header.nomobi nav', function(event) {
		event.preventDefault();
		$(this).addClass('hover');
		$(this).find('ul').addClass('hover');
	});

	$(document).on('click touchstart', 'header.nomobi nav a', function(event) {

		if (!$(this).closest('nav').hasClass('clickable') && !$(this).attr('href')) event.preventDefault(); //prevent FatFingers!

		$('.hover').removeClass('hover clickable');
		$(this).closest('nav').addClass('hover');
		$(this).closest('nav').find('ul').addClass('hover');

	});

	$(document).on('touchend', 'header.nomobi nav.hover a', function(event) {
		$(this).closest('nav').addClass('clickable');
	});

	$('header.nomobi nav ul').each(function(index, el) {
		$(this).akmaxheightbyclass('hover');
	});

	//MOBIL

	$(document).on('click', 'header.mobi div.sandwichicon', function(event) {
		event.preventDefault();

		$sw = $(this).closest('header').find('div.sandwich');

		$(this).toggleClass('open');
		$(this).closest('#logo').toggleClass('open');
		$sw.toggleClass('open');



		if ( $sw.hasClass('open')){
			$sw.slideDown('fast', function() {});
		}
		else{
			$sw.slideUp('fast', function() {});
		}
	});

	// $(document).on('mouseenter', 'header.mobi div.sandwichicon', function(event) {
	// 	if ( $(this).hasClass('open') ) return;
	// 	$(this).trigger('click');
	// });

	// $(document).on('mouseleave', 'header.mobi', function(event) {
	// 	if ( !$(this).find('div.sandwich:first').hasClass('open') ) return;
	// 	$(this).find('div.sandwichicon:first').trigger('click');
	// });


}; nav();



var team = function(){
	$('body.node-type-team').find('article.node-team div.field-collection-container').each(function(index, el) {
		$list = $(this);

		// $('article.field.teamloader').remove();
		// $list.before('<article class="field teamloader"></div>');
		// $teamloader = $('article.field.teamloader');

		$list.find('article.node').on('click', function(event) { console.log('hello');

			event.preventDefault();
			// $teamloader.html( $(this).html() );
			$list.find('article.node.opened').removeClass('opened')
			$(this).addClass('opened');
		});

		$list.find('article.node a').on('click', function(event) { console.log('hello');
			event.stopPropagation();
		});

	});
}; team();






var frontpageiconclick = function(){ console.log('frontpageiconclick initiated');

	var $fpi = $('div#container article.node-frontpage section.field-name-field-frontpage-logos article.node')

	$fpi.css('cursor', 'pointer');

	$fpi.on('click', function(event) { console.log('frontpageicon clicked');

		var link = $(this).find('article.field-name-field-subtitle a:first').attr('href');

		console.log(link);
		window.location.href = link;

		return false;

	});
}; frontpageiconclick();
