//AK-ADMIN MENUHACK v2.1

var adminmenu=function(dev){
	
	var am = 'div#admin-menu';
	var al = 'div#aklogin';

	console.log('Adminmenu/Login hidden. Press ^ or a to toggle. Dev is '+dev);

	$(document).on('keydown', function(e) {
		console.log(e.which);

		if ( $('input').is(':focus') ) console.log('Admin prevented');

		else if( (e.which == 65 || e.which == 192) && $('body').hasClass('am-visible') ) amhide();
		else if( (e.which == 65 || e.which == 192) ) amshow();
	  	
	});

	var amhide=function(){
		$('body').removeClass('am-visible');
	}; 

	var amshow=function(){

		if ($(am).length) $('body').addClass('am-visible');
		if ($(al).length) $('body').addClass('am-visible');

	}; 
	
	setTimeout( function(){ 
		if (dev && $(am).length) amshow();
		if ( $('#overlay-container').length) amshow();
	},500);

}; adminmenu();

//ADMINMENU END




//HIDE MESSAGES ON HOVER
var hidemessages = function(init){ console.log('hidemessages() called');

	$('div#messagescontainer').on('click', function() {
		$(this).fadeOut(200)
	});

}; hidemessages(true);
